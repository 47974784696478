<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">ຂໍ້ມູນສ່ວນຕົວ</h1>
      <div class="columns">
        <div class="column is-6">
          <b-field label="ຊື່ແທ້" horizontal>
            <b-input v-model="model.firstname" placeholder="ຊື່" required />
          </b-field>
          <b-field label="ນາມສະກຸນ" horizontal>
            <b-input v-model="model.lastname" placeholder="ນາມສະກຸນ" required />
          </b-field>
          <b-field label="ເບີໂທ" horizontal>
            <b-input v-model="model.tel" placeholder="ເບີໂທ" required />
          </b-field>
          <hr />
          <div class="has-text-centered">
            <b-button
              class="button is-primary"
              @click="save()"
              icon-left="check"
            >
              ບັນທຶກ
            </b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import { get } from "vuex-pathify";
export default {
  name: "Profile",
  computed: {
    user: get("user"),
  },
  data() {
    return {
      model: {},
    };
  },
  mounted() {
    this.model = this.user;
  },
  methods: {
    save() {
      axios
        .post("profile/update", {
          firstname: this.model.firstname,
          lastname: this.model.lastname,
          tel: this.model.tel,
        })
        .then((res) => {
          console.log(res);
          swal.fire("ສຳເລັດ", "", "success");
        });
    },
  },
};
</script>
